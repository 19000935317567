import marked from "marked";

/* ==========================================================================
   #custom.js
   ========================================================================== */

/**
 * This handles the registration of widgets and custom code.
 */

/* register
   ========================================================================== */

export default function custom(params) {

	//
	// Hide or show the Library News, depending on the current route. There's
	// a page.js problem with when elements get rendered that forced me to
	// put this in the template.
	//
	handleBlog(params);

	//
	// Force the page to scroll to the top when a link is clicked. This was
	// placed in custom.js and not routing.js, because it based on the site's
	// needs. Also, ensure we are not scrolling if there is a bookmark in the
	// URL.
	//
	if(location.href.indexOf("#") == -1) {
		window.scrollTo(0, 0);
	}
}


/* handleBlog();
   ========================================================================== */

function handleBlog(params) {

	let el = document.getElementById("js-blog-container");

	//
	// Hides or shows the blog container depending on the current route.
	//
	if (params.folder == "main" && params.page == "home") {
		el.setAttribute("style", "display: block");
	}
	else {
		el.setAttribute("style", "display: none");
	}
}
